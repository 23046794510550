export const data = [
  {
    id: 1,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 2,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 3,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 4,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 5,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 6,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 7,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 8,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 9,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
  {
    id: 10,
    name: "Coming Soon!",
    category: "",
    image:
      "https://png.pngtree.com/png-vector/20221124/ourmid/pngtree-under-construction-with-triangle-shape-for-warning-sign-png-image_6471440.png",
    price: "$$$$$$$$$",
    note: "",
  },
];

const quotes = [
  {
    quote:
      "The ambiance transports you straight to Rome, and the pasta is as authentic as it gets! Truly a gem.",
  },
  {
    quote:
      "Every dish at Kiss of Rome tastes like it's made with love. The flavors are rich and remind me of my time in Italy.",
  },
  {
    quote:
      "Outstanding service! The staff at Kiss of Rome make you feel like family. The Risotto is a must-try!",
  },
  {
    quote:
      "We celebrated our anniversary at Kiss of Rome and it was magical. The tiramisu is the best I've ever had!",
  },
  {
    quote:
      "From the fresh ingredients to the exquisite wine list, everything at Kiss of Rome screams quality.",
  },
  {
    quote:
      "Kiss of Rome is our go-to for date night. The romantic setting and the divine Spaghetti Carbonara never disappoint.",
  },
  {
    quote:
      "If you want a true taste of Italian cuisine, Kiss of Rome is the place to go. Their seafood dishes are out of this world!",
  },
  {
    quote:
      "Never thought I’d find such authentic Italian cuisine outside of Italy. The Lasagna is sublime and a real tribute to traditional recipes.",
  },
];

export function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex].quote;
}
